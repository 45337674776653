import React from "react";
import "./style.scss";
import { CustomEventGA } from "../../Utils/CustomEventGA";
import usePixelFacebook from "../../Utils/Hooks/usePixelFacebook";

const Download = () => {
  usePixelFacebook();
  return (
    <div className="download-wrapper">
      <h2>
        Téléchargez l'application et cherchez sans limite n'importe où, sur
        n'importe quel appareil
      </h2>

      <div className="links">
        <div className="download-call">
          <div className="qr-code">
            <img src={require("../../../assets/qr-code.webp")} alt="qr-code" />
          </div>
          <div className="download-text">
            <img
              src={require("../../../assets/direction.svg").default}
              alt="direction"
            />
            <h3>Téléchargez l'application gratuitement</h3>
            <p>
              Scannez le code QR avec l'appareil photo de votre smartphone pour
              téléchargez l'application
            </p>
          </div>
        </div>

        <div className="app-stores">
          <a
            className="app-store-link"
            href="https://play.google.com/store/apps/details?id=com.mooshir"
            target="_blank"
          >
            <div
              onClick={() => {
                CustomEventGA({
                  cat: "click-button",
                  action: "google-download-button-home-footer",
                  label: "google-download-button-home-footer",
                });
              }}
              className="app-store"
            >
              <img
                src={require("../../../assets/playstore.svg").default}
                alt="app"
              />
              <div className="app-store-text">
                <p>Get it on</p>
                <span>Google Play</span>
              </div>
            </div>
          </a>
          <a
            className="app-store-link"
            href="https://apps.apple.com/app/mooshir-immobilier-services/id6453938369"
            target="_blank"
          >
            <div
              onClick={() => {
                CustomEventGA({
                  cat: "click-button",
                  action: "ios-download-button-home-footer",
                  label: "ios-download-button-home-footer",
                });
              }}
              className="app-store"
            >
              <img
                src={require("../../../assets/apple.svg").default}
                alt="app"
              />
              <div className="app-store-text">
                <p>download it on</p>
                <span>Apple store</span>
              </div>
            </div>
          </a>
          <a
            className="app-store-link"
            href="https://appgallery.huawei.com/app/C108820629"
            target="_blank"
          >
            <div
              onClick={() => {
                CustomEventGA({
                  cat: "click-button",
                  action: "huawei-download-button-home-footer",
                  label: "huawei-download-button-home-footer",
                });
              }}
              className="app-store"
            >
              <img
                src={require("../../../assets/huawei.svg").default}
                alt="app"
              />
              <div className="app-store-text">
                <p>explore it on</p>
                <span>AppGallery</span>
              </div>
            </div>
          </a>
        </div>

        <div className="available-stores">
          <h3>Disponible sur</h3>

          <div className="available-store">
            <a
              className="app-store-link"
              href="https://play.google.com/store/apps/details?id=com.mooshir"
              target="_blank"
            >
              <div
                onClick={() => {
                  CustomEventGA({
                    cat: "click-button",
                    action: "google-download-button-home-footer-mobile",
                    label: "google-download-button-home-footer-mobile",
                  });
                }}
                className="app-store"
                style={{ background: "#3DDC8420" }}
              >
                <img src={require("../../../assets/playstore.png")} alt="app" />
                <div className="text">
                  <p>Get it on</p>
                  <h4>Google Play</h4>
                </div>
              </div>
            </a>
            <a
              className="app-store-link"
              href="https://apps.apple.com/app/mooshir-immobilier-services/id6453938369"
              target="_blank"
            >
              <div
                onClick={() => {
                  CustomEventGA({
                    cat: "click-button",
                    action: "ios-download-button-home-footer-mobile",
                    label: "ios-download-button-home-footer-mobile",
                  });
                }}
                className="app-store"
                style={{ background: "#3DDC8420" }}
              >
                <img
                  src={require("../../../assets/apple.svg").default}
                  alt="app"
                />
                <div className="text">
                  <p>Dowbload on the</p>
                  <h4>App store</h4>
                </div>
              </div>
            </a>
            <a
              className="app-store-link"
              href="https://appgallery.huawei.com/app/C108820629"
              target="_blank"
            >
              <div
                onClick={() => {
                  CustomEventGA({
                    cat: "click-button",
                    action: "huawei-download-button-home-footer-mobile",
                    label: "huawei-download-button-home-footer-mobile",
                  });
                }}
                className="app-store"
                style={{ background: "#3DDC8420" }}
              >
                <img src={require("../../../assets/huawei.png")} alt="app" />
                <div className="text">
                  <p>Explore it on</p>
                  <h4>AppGallery</h4>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div className="landscape">
          <img
            src={require("../../../assets/landscape.svg").default}
            alt="landscape"
          />
        </div>
      </div>

      <div className="mooshir-app-description">
        <div className="mooshir-app-description-content">
          <div className="mobile-app-logo">
            <img
              src={require("../../../assets/Mooshir.svg").default}
              alt="gradient"
            />
          </div>

          <div className="mobile-app-landscape">
            <img
              src={require("../../../assets/landscape.svg").default}
              alt="gradient"
            />
          </div>

          <div className="mooshir-app-description-text">
            <div className="description-text">
              <h2>En un clic</h2>
              <h3>
                <div>Trouver l'annonce</div>
                <div>qui vous convient</div>
              </h3>
            </div>

            <img
              src={require("../../../assets/download-modal-app.webp")}
              alt="download-mockup"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
