import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";

const usePixelFacebook = () => {
  useEffect(() => {
    // Initialize Facebook Pixel with your Pixel ID
    const webViewPixelId = "1582137679004992"; // Replace with your actual Pixel ID
    ReactPixel.init(webViewPixelId);

    // Track page views (optional, you can track other events as well)
    ReactPixel.pageView();

    //   ReactPixel.fbq("track", "PageView");

    //   const trackEvent = (event, data) => {
    //     ReactPixel.fbq("track", event, data);
    //   };

    //   const trackCustomEvent = (event, data) => {
    //     ReactPixel.fbq("trackCustom", event, data);
    //   }
  }, []);
};

export default usePixelFacebook;
