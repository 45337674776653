import React, { useContext, useEffect } from "react";
import Hero from "../Hero";
import "./style.scss";
import Services from "../Services";
// import Solutions from "../Solutions";
import Design from "../design";
import Download from "../download";
import Footer from "../../components/Footer";
import Partners from "../Partners";
import AOS from "aos";
import "aos/dist/aos.css";
import { CustomEventGA } from "../../Utils/CustomEventGA";
import { GeneralContext } from "../../../contexts/General";
import ShowCase from "../ShowCase";
import usePixelFacebook from "../../Utils/Hooks/usePixelFacebook";

const Landing = (props) => {
  usePixelFacebook();

  const { forceOpenModal } = props;
  const { isMenuOpen, setIsModal, isModal } = useContext(GeneralContext);
  useEffect(() => {
    setIsModal(forceOpenModal);
  }, []);
  React.useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 90,
    });
  }, []);
  // const { isModal, isMenuOpen } = useContext(GeneralContext)
  return (
    <div className="app">
      {/* {isModal && <DownloadModal />}
            {isMenuOpen && <Menu />} */}
      <div
        data-aos="fade-up" //Here you can use any of the AOS animations
      >
        <Hero />
      </div>

      <div className="second-call-to-download">
        <div
          data-aos="fade-up" //Here you can use any of the AOS animations
        >
          <div className="links">
            <div className="download-call">
              <div className="qr-code">
                <img
                  src={require("../../../assets/qr-code.webp")}
                  alt="qr-code"
                />
              </div>
              <div className="download-text">
                <img
                  src={require("../../../assets/direction.svg").default}
                  alt="direction"
                />
                <h3>Téléchargez l'application gratuitement</h3>
                <p>
                  Scannez le code QR avec l'appareil photo de votre smartphone
                  pour téléchargez l'application
                </p>
              </div>
            </div>

            <div className="app-stores">
              <a
                className="app-store-link"
                href="https://play.google.com/store/apps/details?id=com.mooshir"
                target="_blank"
              >
                <div
                  onClick={() => {
                    CustomEventGA({
                      cat: "click-button",
                      action: "google-download-button-home-hero",
                      label: "google-download-button-home-hero",
                    });
                  }}
                  className="app-store"
                >
                  <img
                    src={require("../../../assets/playstore.svg").default}
                    alt="app"
                  />
                  <div className="app-store-text">
                    <p>Get it on</p>
                    <span>Google Play</span>
                  </div>
                </div>
              </a>
              <a
                className="app-store-link"
                href="https://apps.apple.com/app/mooshir-immobilier-services/id6453938369"
                target="_blank"
              >
                <div
                  onClick={() => {
                    CustomEventGA({
                      cat: "click-button",
                      action: "ios-download-button-home-hero",
                      label: "ios-download-button-home-hero",
                    });
                  }}
                  className="app-store"
                >
                  <img
                    src={require("../../../assets/apple.svg").default}
                    alt="app"
                  />
                  <div className="app-store-text">
                    <p>download it on</p>
                    <span>Apple store</span>
                  </div>
                </div>
              </a>
              <a
                className="app-store-link"
                href="https://appgallery.huawei.com/app/C108820629"
                target="_blank"
              >
                <div
                  onClick={() => {
                    CustomEventGA({
                      cat: "click-button",
                      action: "huawei-download-button-home-hero",
                      label: "huawei-download-button-home-hero",
                    });
                  }}
                  className="app-store"
                >
                  <img
                    src={require("../../../assets/huawei.svg").default}
                    alt="app"
                  />
                  <div className="app-store-text">
                    <p>explore it on</p>
                    <span>AppGallery</span>
                  </div>
                </div>
              </a>
            </div>

            <div className="landscape">
              <img
                src={require("../../../assets/landscape.svg").default}
                alt="landscape"
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div
        data-aos="fade-up" //Here you can use any of the AOS animations
      > */}
      <ShowCase />
      {/* </div> */}

      <div
        data-aos="fade-up" //Here you can use any of the AOS animations
      >
        <Services />
      </div>

      <div
        data-aos="fade-up" //Here you can use any of the AOS animations
      >
        <Partners variant={"product"} />
      </div>

      <Design />
      <div
        data-aos="fade-up" //Here you can use any of the AOS animations
      >
        <Partners variant={"service"} />
      </div>
      {/* <div
        data-aos="fade-up" //Here you can use any of the AOS animations
      >
        <Solutions />
      </div> */}

      <div
        data-aos="fade-up" //Here you can use any of the AOS animations
      >
        <Download />
      </div>

      <Footer backgroundColor={"#EAF7FF"} />
    </div>
  );
};

export default Landing;
