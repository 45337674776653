import React, { useEffect, useRef, useState } from "react";
import "./style.scss";

import { motion, useScroll, useTransform, useInView } from "framer-motion";

function useParallax(value, distance) {
  return useTransform(value, [1, 0], [distance, -distance]);
}
const ShowCase = (props) => {
  const ref = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end end"],
  });

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const isInView = useInView(ref);
  const [isModal, setIsModal] = useState(false);
  const scale4 = useTransform(scrollYProgress, [0, 1], [0.5, 1]);

  var iframe = document.getElementById("iframe");

  // function playFullscreen() {
  //   var requestFullScreen =
  //     iframe?.requestFullScreen ||
  //     iframe?.mozRequestFullScreen ||
  //     iframe?.webkitRequestFullScreen;
  //   if (requestFullScreen) {
  //     requestFullScreen?.bind(iframe)();
  //   }
  // }

  const [videoFullScreen, setVideoFullScreen] = useState(false);

  return (
    <>
      <div id="show-case" className="show-case">
        <motion.div
          ref={ref}
          className="parent"
          style={{
            scale: scale4,
          }}
        >
          <div className="title_container">
            <div
              onClick={() => {
                // playFullscreen();
                setVideoFullScreen(true);

                console.log(videoFullScreen);
              }}
              // style={{ scale: scale4 }}
              id="showCase_title"
              className="showCase_title"
              style={{
                display: videoFullScreen ? "none" : "flex",
              }}
            >
              <h1 id="text">Découvrir les fonctionnalités de Mooshir</h1>
              <button class="glass-button">
                <img
                  className="play-image"
                  alt="icon"
                  src={require("../../../assets/buttonPlay.svg").default}
                />
              </button>
            </div>
          </div>
          <div class="embed-container">
            {isMobile ? (
              <iframe
                src={`https://player.vimeo.com/video/995377319?loop=true&autoplay=true&muted=${!videoFullScreen}&controls=${videoFullScreen}`}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture;"
                webkitAllowFullScreen
                mozallowfullscreen
                allowFullScreen
                // className="video-vimeo"
              ></iframe>
            ) : (
              <iframe
                src={`https://player.vimeo.com/video/995151981?loop=true&autoplay=true&muted=${!videoFullScreen}&controls=${videoFullScreen}`}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture;"
                webkitAllowFullScreen
                mozallowfullscreen
                allowFullScreen
                // className="video-vimeo"
              ></iframe>
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default ShowCase;
